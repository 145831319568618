<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    title="样板间"
    width="632px"
    class="f-dialog"
  >
    <f-upload-extend
      class="mt20"
      ref="uploader"
      :default-file-list="options.modelRoomImagePaths"
      @onSuccess="(fileList, imageList) => uploadSuccess(fileList, imageList)"
      @remove="target => handleImgRemove(target)"
      @drag="imageList => dragSort(imageList)"
    ></f-upload-extend>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import FUploadExtend from "@/components/upload/extend";
import { updateImages, deleteImage } from "@/api/estate";
export default {
  components: { FUploadExtend },
  props: {
    id: {
      type: (String, Number),
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  methods: {
    async updateImages(imageList) {
      // 编辑样板间
      const res = await updateImages({
        id: this.id,
        imageList,
        imageType: 2 //1户型图 2 样板间
      });
      if (res) {
        this.$emit("refresh");
      }
    },
    // 删除样板间
    async handleImgRemove(target) {
      const targetId = this.options.modelRoomImageList.find(
        item => item.imagePath === target
      ).id;
      const res = await deleteImage({ id: targetId });
      if (res) {
        console.log(res);
        this.$emit("refresh");
      }
    },
    async uploadSuccess(fileList, imageList) {
      // 上传样板间
      this.updateImages(imageList);
    },
    dragSort(imageList) {
      // 拖动排序
      this.updateImages(imageList);
    }
  }
};
</script>
<style scoped lang="scss"></style>
