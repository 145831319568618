<template>
  <div class="base-container">
    <!--header-mol-->
    <div class="header-mol">
      <div class="info">
        <div class="title">户型管理</div>
        <div class="summary"></div>
      </div>
      <div class="action">
        <el-button type="primary" icon="el-icon-plus" @click="editHousetype"
          >发布户型</el-button
        >
      </div>
    </div>
    <!--header-mol end-->
    <div class="filter">
      <el-input
        placeholder="文章标题"
        v-model="queryData.name"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        class="select"
        v-model="queryData.status"
        placeholder="状态"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="queryData.propertyType"
        placeholder="类型"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in propertyTypes"
          :value="item.value"
          :label="item.label"
          :key="item.value"
        ></el-option>
      </el-select>
    </div>
    <el-table border class="mt20" :data="list">
      <el-table-column label="户型信息" width="500">
        <div class="house-type-info" slot-scope="{ row }">
          <el-image :src="row.houseTypeImagePaths[0]"></el-image>
          <div class="info">
            <div class="title">
              <template v-if="[1, 2, 3].includes(row.propertyType)">
                {{ row.bedRoomQuantity }}室{{ row.livingRoomQuantity }}厅{{
                  row.restRoomQuantity
                }}卫
              </template>
              <template v-else>{{ row.name }}</template>
              <span class="tag" v-if="row.top">荐</span>
            </div>
            <p v-if="[1, 2, 3].includes(row.propertyType)">{{ row.name }}</p>
            <div class="other">
              用途 <span>{{ row.propertyTypeName }}</span>
              建面
              <span>
                {{ row.buildingArea }}
                <template v-if="[4, 5].includes(row.propertyType)">
                  - {{ row.buildingAreaTo }} </template
                >㎡
              </span>
              <template v-if="[1, 2, 3].includes(row.propertyType)">
                朝向
                <span>{{ row.orientation }}</span>
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="样板间">
        <template v-slot="{ row }">
          <el-button
            type="text"
            :class="{ gray: !row.modelRoomImagePaths.length }"
            @click="addModelRoom(row)"
          >
            <svg-icon icon-class="door"></svg-icon>
            {{
              row.modelRoomImagePaths.length
                ? `样板间(${row.modelRoomImagePaths.length})`
                : "上传图片"
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="{ row }">
          <span v-if="row.status == 1">已发布</span>
          <span class="red" v-if="row.status == 0">草稿</span>
          <span v-if="row.status == 2">已下架</span>
        </template>

        <span class="red">草稿</span>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template v-slot="{ row }">
          <el-button type="text" @click="deploy(row.id)" v-if="row.status == 0"
            >发布</el-button
          >
          <el-button
            type="text"
            v-if="row.status == 1 || row.status == 2"
            @click="housetypeOn(row)"
          >
            {{ row.status == 1 ? "下架" : "上架" }}
          </el-button>
          <el-button type="text" @click="top(row)">
            {{ row.top ? "取荐" : "推荐" }}
          </el-button>
          <el-button type="text" @click="editHousetype(row)">编辑</el-button>
          <el-button type="text" v-if="row.status == 0" @click="del(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
    <edit
      :title="curRow.id ? '编辑户型' : '发布户型'"
      :visible.sync="showDialog"
      :options="curRow"
      @refresh="search"
    ></edit>
    <edit-model-room
      :visible.sync="showModelRoomDialog"
      :options="curRow"
      :id="curRow.id"
      @refresh="getList"
    ></edit-model-room>
  </div>
</template>

<script>
import { enumeration } from "@/config";
import edit from "./_components/edit";
import editModelRoom from "./_components/editModelRoom";
import {
  getHousetypeList,
  housetypeTop,
  housetypeDeploy,
  housetypeOn,
  housetypeDelete
} from "@/api/estate";
export default {
  components: { edit, editModelRoom },
  data() {
    return {
      showDialog: false,
      showModelRoomDialog: false,
      list: [],
      total: 0,
      curRow: {},
      status: [
        {
          label: "草稿",
          value: 0
        },
        {
          label: "已发布",
          value: 1
        },
        {
          label: "已下架",
          value: 2
        }
      ],
      propertyTypes: enumeration.propertyTypes,
      pageSizes: [10, 20, 30, 50],
      queryData: {
        name: "",
        propertyType: null,
        status: null, // 0 是草稿 1 已发布 2 已下架
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      // 获取户型列表
      const res = await getHousetypeList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    editHousetype(row) {
      // 编辑户型
      this.showDialog = true;
      this.curRow = row.id ? row : {};
    },
    async top({ id, top }) {
      // 推荐/取消推荐
      const res = await housetypeTop({
        id,
        top: !top
      });
      if (res) {
        this.$showSuccess(top ? "取荐成功" : "推荐成功");
        this.getList();
      }
    },
    async deploy(id) {
      // 发布户型
      const res = await housetypeDeploy({ id });
      if (res) {
        this.$showSuccess("发布成功");
        this.getList();
      }
    },

    async housetypeOn({ id, status }) {
      // 上架/下架户型
      const res = await housetypeOn({ id });
      if (res) {
        this.$showSuccess(status == 1 ? "下架成功" : "上架成功");
        this.getList();
      }
    },
    async del(id) {
      // 删除户型
      const res = await housetypeDelete({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.search();
      }
    },
    addModelRoom(row) {
      // 编辑样板间
      this.showModelRoomDialog = true;
      this.curRow = row;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-table {
  td {
    vertical-align: top;
  }
  .el-button {
    padding: 0;
  }
  .gray.el-button--text {
    color: #aaa;
  }
}
.filter {
  .select {
    width: 150px;
    margin-left: 10px;
  }
}
.house-type-info {
  margin-left: -12px;
  display: flex;
  .el-image {
    width: 120px;
    height: 90px;
    margin-right: 10px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    line-height: 140%;
    display: flex;
    align-items: center;
    height: 28px;
    .tag {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: #f60;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 100%;
  }
  .other {
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 5px;
    color: #aaa;
    span {
      color: #333;
      margin-right: 5px;
    }
  }
}
.door {
  color: #aaa;
  svg {
    margin-right: 5px;
  }
  &.active {
    color: $primary;
  }
}
</style>
