var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": _vm.title,
      "width": "514px",
      "top": "50px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.openDialog,
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "propertyType",
      "label": "用途："
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    model: {
      value: _vm.formData.propertyType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyType", $$v);
      },
      expression: "formData.propertyType"
    }
  }, _vm._l(_vm.propertyTypes, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "prop": "name",
      "label": "名称："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), [1, 2, 3].includes(_vm.formData.propertyType) ? _c('el-form-item', {
    staticClass: "room",
    attrs: {
      "label": "室厅卫："
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "bedRoomQuantity",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    staticClass: "first",
    attrs: {
      "placeholder": "几室",
      "value": _vm.formData.bedRoomQuantity
    },
    on: {
      "input": function input(e) {
        return _vm.formData.bedRoomQuantity = _vm.onInput(e, 'int');
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("室")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "prop": "livingRoomQuantity",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "几厅",
      "value": _vm.formData.livingRoomQuantity
    },
    on: {
      "input": function input(e) {
        return _vm.formData.livingRoomQuantity = _vm.onInput(e, 'int');
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("厅")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "prop": "restRoomQuantity",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    staticClass: "last",
    attrs: {
      "placeholder": "几卫",
      "value": _vm.formData.restRoomQuantity
    },
    on: {
      "input": function input(e) {
        return _vm.formData.restRoomQuantity = _vm.onInput(e, 'int');
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("卫")])], 2)], 1)], 1) : _vm._e(), [1, 2, 3].includes(_vm.formData.propertyType) ? _c('el-form-item', {
    staticClass: "area",
    attrs: {
      "prop": "buildingArea",
      "label": "建筑面积：",
      "rules": [_vm.rules.required(), _vm.ruleTwoDecimalPlaces]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "最多可保留小数后2位",
      "value": _vm.formData.buildingArea
    },
    on: {
      "input": function input(e) {
        return _vm.formData.buildingArea = _vm.onInput(e);
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1) : _c('el-form-item', {
    staticClass: "area-range",
    attrs: {
      "label": "建筑面积：",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-col', {
    attrs: {
      "span": 9
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "buildingArea",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "最小面积",
      "value": _vm.formData.buildingArea
    },
    on: {
      "input": function input(e) {
        return _vm.formData.buildingArea = _vm.onInput(e);
      }
    }
  })], 1)], 1), _c('el-col', {
    staticClass: "line",
    attrs: {
      "span": 2
    }
  }, [_vm._v("-")]), _c('el-col', {
    attrs: {
      "span": 13
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "buildingAreaTo",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "最大面积",
      "value": _vm.formData.buildingAreaTo
    },
    on: {
      "input": function input(e) {
        return _vm.formData.buildingAreaTo = _vm.onInput(e);
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1)], 1)], 1), [1, 2, 3].includes(_vm.formData.propertyType) ? _c('el-form-item', {
    attrs: {
      "label": "朝向：",
      "prop": "orientation"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "请选择，支持多选",
      "multiple": ""
    },
    model: {
      value: _vm.formData.orientation,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "orientation", $$v);
      },
      expression: "formData.orientation"
    }
  }, _vm._l(_vm.orientationList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "prop": "houseTypeImagePaths",
      "label": [1, 2, 3].includes(_vm.formData.propertyType) ? '户型图：' : '平面图：',
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v("支持jpg、png、gif格式，建议大小1M以内")]), _c('f-upload', {
    ref: "uploader",
    attrs: {
      "type": 2,
      "limitSize": 1,
      "isCompress": false,
      "accept": ".jpg,.jpeg,.png,.gif",
      "defaultFileList": _vm.fileList
    },
    on: {
      "on-success": _vm.uploadSuccess
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "description",
      "label": "描述："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "选填，建议200字以内",
      "type": "textarea",
      "rows": "6",
      "resize": "none"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提 交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }