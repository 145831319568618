<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    width="514px"
    top="50px"
    :close-on-click-modal="false"
    class="f-dialog"
    @open="openDialog"
    @close="cancel"
  >
    <el-form :model="formData" ref="form" label-width="100px">
      <el-form-item prop="propertyType" label="用途：">
        <el-radio-group v-model="formData.propertyType" size="medium">
          <el-radio-button
            v-for="item in propertyTypes"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="name" label="名称：">
        <el-input v-model="formData.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item
        label="室厅卫："
        class="room"
        v-if="[1, 2, 3].includes(formData.propertyType)"
      >
        <el-form-item prop="bedRoomQuantity" :rules="[rules.required()]">
          <el-input
            placeholder="几室"
            :value="formData.bedRoomQuantity"
            @input="e => (formData.bedRoomQuantity = onInput(e, 'int'))"
            class="first"
          >
            <template slot="append">室</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="livingRoomQuantity" :rules="[rules.required()]">
          <el-input
            placeholder="几厅"
            :value="formData.livingRoomQuantity"
            @input="e => (formData.livingRoomQuantity = onInput(e, 'int'))"
          >
            <template slot="append">厅</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="restRoomQuantity" :rules="[rules.required()]">
          <el-input
            placeholder="几卫"
            :value="formData.restRoomQuantity"
            @input="e => (formData.restRoomQuantity = onInput(e, 'int'))"
            class="last"
          >
            <template slot="append">卫</template>
          </el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item
        prop="buildingArea"
        label="建筑面积："
        :rules="[rules.required(), ruleTwoDecimalPlaces]"
        class="area"
        v-if="[1, 2, 3].includes(formData.propertyType)"
      >
        <el-input
          placeholder="最多可保留小数后2位"
          :value="formData.buildingArea"
          @input="e => (formData.buildingArea = onInput(e))"
        >
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="建筑面积："
        :rules="[rules.required()]"
        class="area-range"
        v-else
      >
        <el-col :span="9">
          <el-form-item prop="buildingArea" :rules="[rules.required()]">
            <el-input
              placeholder="最小面积"
              :value="formData.buildingArea"
              @input="e => (formData.buildingArea = onInput(e))"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="13">
          <el-form-item prop="buildingAreaTo" :rules="[rules.required()]">
            <el-input
              placeholder="最大面积"
              :value="formData.buildingAreaTo"
              @input="e => (formData.buildingAreaTo = onInput(e))"
            >
              <template slot="append">㎡</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="朝向："
        prop="orientation"
        v-if="[1, 2, 3].includes(formData.propertyType)"
      >
        <el-select
          placeholder="请选择，支持多选"
          v-model="formData.orientation"
          multiple
          style="width:100%;"
        >
          <el-option
            v-for="(item, index) in orientationList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="houseTypeImagePaths"
        :label="
          [1, 2, 3].includes(formData.propertyType) ? '户型图：' : '平面图：'
        "
        :rules="[rules.required()]"
      >
        <div class="tip">支持jpg、png、gif格式，建议大小1M以内</div>
        <f-upload
          ref="uploader"
          :type="2"
          :limitSize="1"
          :isCompress="false"
          accept=".jpg,.jpeg,.png,.gif"
          :defaultFileList="fileList"
          @on-success="uploadSuccess"
        ></f-upload>
      </el-form-item>
      <el-form-item prop="description" label="描述：">
        <el-input
          placeholder="选填，建议200字以内"
          v-model="formData.description"
          type="textarea"
          rows="6"
          resize="none"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import { enumeration } from "@/config";
import FUpload from "@/components/upload";
import { editProjectData } from "@/api/distribution";
import { housetypeUpdate } from "@/api/estate";
const formData = {
  name: "",
  propertyType: 1, //  1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺
  bedRoomQuantity: "",
  livingRoomQuantity: "",
  restRoomQuantity: "",
  buildingArea: "", // 建筑面积
  buildingAreaTo: "", // 建筑面积
  orientation: "", // 朝向
  description: "", // 描述
  houseTypeImagePaths: [] // 户型图
};
export default {
  components: { FUpload },
  props: {
    title: {
      type: String,
      default: ""
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      ruleTwoDecimalPlaces: {
        message: "必须为数字，且最多可保留小数后2位",
        pattern: /^\d{0,6}?(\.\d{0,2})?$/,
        trigger: "blur"
      },
      orientationList: ["朝东", "朝南", "朝西", "朝北", "南北通透"],
      propertyTypes: enumeration.propertyTypes,
      formData: this.$deepClone(formData),
      fileList: [] // 上传的文件信息
    };
  },
  methods: {
    onInput(value, type) {
      // 限制输入只能为数字和.
      // 室厅卫只能输入整数
      // 面积，最多保留两位小数
      let reg = type == "int" ? /[^\d]/g : /[^\d.]/g;
      let val = value.replace(reg, "");
      let firstDot = val.indexOf(".");
      let lastDot = val.lastIndexOf(".");
      // 如果输入有多个.则最后一个点及期后边的清空
      if (lastDot !== firstDot) {
        val = val.substr(0, lastDot);
      }
      // 小数点后超过两位则截取 .xx 长度为 3
      if (firstDot !== -1) {
        val = val.substr(0, firstDot + 3);
      }
      return val;
    },
    openDialog() {
      this.$nextTick(() => {
        if (this.options.id) {
          this.formData = {
            ...this.formData,
            id: this.options.id,
            name: this.options.name,
            propertyType: this.options.propertyType, //  1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺
            bedRoomQuantity: this.options.bedRoomQuantity,
            livingRoomQuantity: this.options.livingRoomQuantity,
            restRoomQuantity: this.options.restRoomQuantity,
            buildingArea: this.options.buildingArea, // 建筑面积
            buildingAreaTo: this.options.buildingAreaTo, // 建筑面积
            orientation: this.options.orientation.split(","), // 朝向
            description: this.options.description, // 描述
            houseTypeImagePaths: this.options.houseTypeImagePaths // 户型图
          };
          this.fileList = this.options.houseTypeImageList.map(item => {
            return {
              ...item,
              url: item.imagePath
            };
          });
        }
      });
    },
    async uploadSuccess(fileList) {
      this.fileList = fileList;
      this.formData.houseTypeImagePaths = fileList.map(item => item.url);
    },
    cancel() {
      this.$emit("update:visible", false);
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
      this.formData.buildingAreaTo = "";
      this.formData.buildingArea = "";
      this.fileList = [];
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        let params = {
          ...this.formData,
          orientation: [1, 2, 3].includes(this.formData.propertyType)
            ? this.formData.orientation.join(",")
            : "" // 商铺、写字楼无朝向字段
        };
        const res = await housetypeUpdate(params);
        if (res) {
          this.$showSuccess("编辑成功");
          this.cancel();
          this.$emit("refresh");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.tip {
  color: #aaa;
}
::v-deep .el-dialog__body {
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  padding-right: 30px;
}
.area {
  ::v-deep {
    .el-input-group__append {
      padding: 0 12px;
    }
  }
}
.area-range {
  .line {
    text-align: center;
  }
  ::v-deep {
    .el-form-item__content {
      display: flex;
    }
    .el-input-group__append {
      padding: 0 12px;
    }
  }
}
.room {
  ::v-deep {
    .el-form-item__content {
      display: flex;
    }
    .el-input-group__append {
      padding: 0 12px;
      border-radius: 0;
      border-right-width: 0;
    }

    .last .el-input-group__append {
      border-radius: 0 4px 4px 0;
      border-right-width: 1px;
    }
    .el-input-group--append .el-input__inner {
      border-radius: 0;
    }
    .first.el-input-group--append .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
}
</style>
