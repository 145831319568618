var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "样板间",
      "width": "632px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('f-upload-extend', {
    ref: "uploader",
    staticClass: "mt20",
    attrs: {
      "default-file-list": _vm.options.modelRoomImagePaths
    },
    on: {
      "onSuccess": function onSuccess(fileList, imageList) {
        return _vm.uploadSuccess(fileList, imageList);
      },
      "remove": function remove(target) {
        return _vm.handleImgRemove(target);
      },
      "drag": function drag(imageList) {
        return _vm.dragSort(imageList);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }